import { useCallback, useEffect, useState } from 'react'

import { Box, Grid } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'

import { Game } from 'models/gameModels'

import { useGameContext } from 'contexts/GameProvider'
import { MODAL_TYPES, useModalContext } from 'contexts/ModalProvider'
import { useToastContext } from 'contexts/ToastProvider'

import { CallToAction } from 'components/CallToAction/CallToAction'
import CustomGameCard from 'components/CustomGameCard/CustomGameCard'
import EmptySubPageContent from 'components/EmptySubPageContent/EmptySubPageContent'
import SubPageHeader from 'components/SubPageHeader/SubPageHeader'

import { defaultErrorMessage } from 'assets/alertText'

import { ToastSeverity } from 'enums/ToastSeverityEnum'

import { styles } from './PublishedGames.styles'
import FilterRow from './components/FilterRow/FilterRow'

export const TEST_ID = 'published-games-page'

const PublishedGames = () => {
	const {
		games = [],
		requestGames,
		requestGamesLoading,
		createdGame,
		createGameSuccess,
		editGameError,
		editGameLoading,
		editGameSuccess,
		editedGame,
		requestDeleteGame,
		deleteGameError,
		deleteGameLoading,
		deleteGameSuccess,
		deletedGame,
	} = useGameContext()

	const { showAlert } = useToastContext()

	const [selectedGameType, setSelectedGameType] = useState('')
	const [searchValue, setSearchValue] = useState('')
	const { showModal } = useModalContext()

	useEffect(() => {
		if (createGameSuccess && requestGames) {
			requestGames({
				gameTypeId: selectedGameType,
				gameTitle: searchValue,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createdGame, createGameSuccess])
	useEffect(() => {
		const gameStateStr = sessionStorage.getItem('gameState')
		if (gameStateStr) {
			showModal(MODAL_TYPES.CUSTOM_GAME_MODAL, {
				gameState: JSON.parse(gameStateStr),
			})
			sessionStorage.removeItem('gameState')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (editGameSuccess && requestGames) {
			requestGames({
				gameTypeId: selectedGameType,
				gameTitle: searchValue,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editedGame, editGameSuccess])

	useEffect(() => {
		if (deleteGameSuccess && requestGames) {
			requestGames({
				gameTypeId: selectedGameType,
				gameTitle: searchValue,
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deletedGame, deleteGameSuccess])

	useEffect(() => {
		if (!deleteGameLoading && deleteGameSuccess && showAlert) {
			showAlert({
				severity: ToastSeverity.SUCCESS,
				objectType: 'deleteGame',
				gameName: deletedGame?.gameTitle,
			})
		}

		if (!deleteGameLoading && deleteGameError && showAlert) {
			showAlert(defaultErrorMessage)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteGameError, deleteGameLoading, deleteGameSuccess])

	useEffect(() => {
		if (!editGameLoading && editGameSuccess && showAlert) {
			showAlert({
				severity: ToastSeverity.SUCCESS,
				objectType: 'updateGame',
				gameName: editedGame?.gameTitle,
			})
		}

		if (!editGameLoading && editGameError && showAlert) {
			showAlert(defaultErrorMessage)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editGameError, editGameLoading, editGameSuccess])

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (requestGames) {
				requestGames({
					gameTypeId: selectedGameType,
					gameTitle: searchValue,
				})
			}
		}, 500)

		return () => clearTimeout(delayDebounceFn)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue, selectedGameType])

	const openModal = useCallback(() => {
		showModal(MODAL_TYPES.CUSTOM_GAME_MODAL)
	}, [showModal])

	return (
		<Box data-testid={TEST_ID}>
			<SubPageHeader
				title="Custom Games"
				subtitle="Create and track the engagement with your games"
				backgroundGradient="linear-gradient(90deg, #9DCDF2 0%, #BDDEF6 100%)"
			/>
			{requestGamesLoading ? (
				<Box sx={styles.ProgressContainer}>
					<CircularProgress
						data-testid={`${TEST_ID}-progress`}
						sx={styles.CircularProgressIcon}
					/>
				</Box>
			) : games && games.length > 0 ? (
				<Grid
					container
					spacing={4}
					sx={{ padding: { lg: '3.2rem 9.8rem', xs: '3.2rem' } }}
				>
					<FilterRow
						searchValue={searchValue}
						onSearchInputChange={setSearchValue}
						selectedGameType={selectedGameType}
						onGameTypeChange={setSelectedGameType}
						buttonOnClick={openModal}
					/>
					{games?.map((game: Game) => (
						<Grid
							key={game.gameId}
							item
							lg={4}
							md={4}
							sm={12}
							xs={12}
							sx={{ paddingLeft: '0', gap: '1rem' }}
						>
							<CustomGameCard
								game={game}
								handleDeleteRequest={requestDeleteGame}
							/>
						</Grid>
					))}
				</Grid>
			) : (
				<EmptySubPageContent
					subtitle="No custom games created yet, create a custom game to get started!"
					buttonText="Create Custom Game"
					buttonOnClick={openModal}
				/>
			)}
			<Divider sx={styles.Divider2} />
			<CallToAction data-testid={TEST_ID} />
		</Box>
	)
}

export default PublishedGames
