import React from 'react'

import Search from '@mui/icons-material/Search'
import {
	Grid,
	IconButton,
	InputAdornment,
	OutlinedInput,
	SelectChangeEvent,
	Typography,
} from '@mui/material'
import Box from '@mui/material/Box'

import { BaseDropdown } from 'components/Form'
import RectangleButtonComponent from 'components/RectangleButton'

import { brainstormText } from 'assets/brainstormText'

import { styles } from './FilterRow.styles'

export const TEST_ID = 'published-ideas-filter'

export type Props = {
	buttonOnClick: () => void
	onGameTypeChange: React.Dispatch<React.SetStateAction<string>>
	onSearchInputChange: (val: string) => void
	searchValue: string
	selectedGameType: string
}

export default function FilterRow(props: Props) {
	return (
		<Grid container item sx={styles.filterGrid}>
			<Grid container item sx={styles.filterSection}>
				<Typography
					data-testid={`${TEST_ID}-title`}
					sx={styles.filterText}
				>
					{'Filter by: '}
				</Typography>
				<BaseDropdown
					data-testid="published-ideas-filter-dropdown-1"
					label="Game Type"
					options={
						brainstormText.publishedIdeas
							.gameTypeFilterDropdownOptions
					}
					value={Number(props.selectedGameType)}
					onChange={async (
						event: SelectChangeEvent<string | number | unknown>
					) => props.onGameTypeChange(event.target.value as string)}
				/>
			</Grid>

			<Box
				data-testid="published-ideas-search-input"
				sx={styles.searchBox}
			>
				<RectangleButtonComponent
					color="secondary"
					text={'CREATE CUSTOM GAME'}
					onClick={props.buttonOnClick}
					sx={{ marginRight: '2.4rem' }}
				/>
				<OutlinedInput
					endAdornment={
						<InputAdornment position="end">
							<IconButton>
								<Search />
							</IconButton>
						</InputAdornment>
					}
					inputProps={{ 'data-testid': `${TEST_ID}-search-input` }}
					onChange={(e) => props.onSearchInputChange(e.target.value)}
					placeholder="Search"
					sx={styles.searchBarStyle}
					value={props.searchValue}
				/>
			</Box>
		</Grid>
	)
}
