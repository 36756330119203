import { useState } from 'react'

import { GAME_TYPE } from 'enums/GameTypeEnum'
import { TopicType } from 'enums/TopicTypeEnum'

export function TeeterTotterModel() {
	const [innovationTopic, setInnovationTopic] = useState<TopicType>('')
	const [innovationCompany, setInnovationCompany] = useState<string>('')
	const [companyProblem, setCompanyProblem] = useState<string>('')
	const [termsAgreed, setTermsAgreed] = useState(false)
	const [gameId, setGameId] = useState<string | null>(null)
	const [centerCategory, setCenterCategory] = useState<string>('')
	const [leftExtreme, setLeftExtreme] = useState<string>('')
	const [rightExtreme, setRightExtreme] = useState<string>('')
	const [authorIdeation, setAuthorIdeation] = useState<string>('')

	const gameTypeId = GAME_TYPE.TEETER_TOTTER.value

	const generatePromptsDisabled = () => {
		return innovationTopic.length === 0 || innovationCompany.length === 0
	}

	const submitIdeasDisabled = () => {
		if (gameId && !termsAgreed) return true
		else return false
	}
	const returnIdeation = () => {
		const ideaHTML = [
			`<p><b>Starting from the center category ${centerCategory} two extremes where suggested</b></p>`,
			`<p><b>${leftExtreme}</b></p>`,
			`<p><b>${rightExtreme}</b></p>`,
		].join('')
		return ideaHTML + authorIdeation
	}

	const gameToJSON = () => {
		return {
			gameId: gameId !== null ? gameId : undefined,
			gameTypeId,
			innovationTopic,
			innovationCompany,
			innovationTopicManual: companyProblem,
			authorIdeation: returnIdeation(),
		}
	}

	const resetSoft = () => {
		setAuthorIdeation('')
	}

	const reset = () => {
		resetSoft()
	}

	return {
		authorIdeation,
		centerCategory,
		companyProblem,
		gameToJSON,
		generatePromptsDisabled,
		termsAgreed,
		setTermsAgreed,
		submitIdeasDisabled,
		innovationCompany,
		innovationTopic,
		leftExtreme,
		reset,
		resetSoft,
		rightExtreme,
		setAuthorIdeation,
		setCenterCategory,
		setCompanyProblem,
		setGameId,
		setInnovationCompany,
		setInnovationTopic,
		setLeftExtreme,
		setRightExtreme,
	}
}
