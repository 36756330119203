import React, { useCallback } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
	Box,
	Button,
	Card,
	CardContent,
	Grid,
	IconButton,
	Typography,
} from '@mui/material'

import { styles } from './SynthesisResponse.styles'

export type Props = {
	clearResponse?: VoidFunction
	contentText: string
}

const SynthesisResponse = ({ contentText = '', clearResponse }: Props) => {
	const copyResponse = useCallback(async () => {
		try {
			// set and copy the generated URL to clipboard here
			await navigator.clipboard.writeText(
				`${window.location.origin}${contentText}`
			)
		} catch (e) {
			console.log(`Error copying: ${e}`)
			return e
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentText])

	return (
		<Box
			data-testid="gen-ai-synthesis-response-container"
			sx={styles.container}
		>
			<Card sx={styles.card} variant="outlined">
				<CardContent sx={styles.cardContentReset}>
					<Box
						data-testid="gen-ai-synthesis-response-content-container"
						sx={{
							background: '#E7F8FF',
							padding: {
								lg: '24px',
								md: '24px',
								sm: '24px',
								xs: '8px 16px 8px 16px',
							},
						}}
					>
						<Grid container mb={2}>
							<Grid item flexGrow={1}>
								<Button
									color="secondary"
									variant="text"
									data-testid="gen-ai-synthesis-response-copy-content-button"
									aria-label="copy synthesis response"
									onClick={copyResponse}
									startIcon={<ContentCopyIcon />}
								>
									Copy response
								</Button>
							</Grid>
							{clearResponse && (
								<Grid item alignItems="flex-end">
									<IconButton
										aria-label="clear synthesis response"
										data-testid="gen-ai-synthesis-response-close-content-button"
										color="secondary"
										onClick={clearResponse}
									>
										<CloseIcon />
									</IconButton>
								</Grid>
							)}
						</Grid>
						<Typography sx={styles.contentText} variant="body1">
							{contentText}
						</Typography>
					</Box>
				</CardContent>
			</Card>
		</Box>
	)
}

export default SynthesisResponse
