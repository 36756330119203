import { Button, ButtonProps, Typography } from '@mui/material'

import { styles } from './RectangleButton.styles'

export type Props = {
	invertBackground?: boolean
	isPrimary?: boolean
	onClickAction?: () => void
	text: string
	type?: string
} & ButtonProps & { target?: string }

const RectangleButton = ({
	color = 'primary',
	disabled,
	fullWidth = false,
	href,
	onClickAction,
	text,
	type,
	invertBackground = false,
	sx: customSx = {},
	...restProps
}: Props) => {
	const baseSx = invertBackground ? styles.invertBackgroundColor : {}
	return (
		<Button
			color={color}
			disabled={disabled}
			fullWidth={fullWidth}
			href={href}
			onClick={onClickAction}
			type={type}
			variant={'contained'}
			sx={{ ...baseSx, ...customSx }}
			{...restProps}
		>
			<Typography>{text}</Typography>
		</Button>
	)
}

export default RectangleButton
