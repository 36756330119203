import React from 'react'

import Grid from '@mui/material/Grid'
import { Box, Container } from '@mui/system'

import { Game } from 'models/gameModels'

import BreadcrumbComponent from 'components/BreadcrumbMenu'

import { GAME_TYPE } from 'enums/GameTypeEnum'

import { styles } from './MusicalChairs.styles'

const TEST_ID = 'musical-chairs'

const MusicalChairs = ({ game }: { game?: Game }) => {
	return (
		<Box>
			<BreadcrumbComponent
				gameDescription="for challenging assumptions"
				gameTypeEnum={GAME_TYPE.MUSICAL_CHAIRS}
				game={game}
			/>
			<Grid data-testid={`${TEST_ID}-container`} sx={styles.container} />
			<Container id={`${TEST_ID}-container`}>
				<h1>Musical Chairs redesign coming soon</h1>
			</Container>
		</Box>
	)
}

export default MusicalChairs
