import React, { useRef } from 'react'

import { Box, Button, Grid, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

import RichTextField from 'components/Form/RichTextField/RichTextField'
import IdeaFlameMotivator, {
	useRichTextToIdeas,
} from 'components/IdeaFlameMotivator/IdeaFlameMotivator'

import { styles as Section1Styles } from '../Section1/Section1.styles'

export const TEST_ID = 'leap-frog-section3'

export type Props = {
	authorIdeation: string
	buttonDisabled: () => boolean
	cautionText?: string
	handleSubmitIdeas: () => void
	handleUserResponse: (user: string) => void
	isAiLoading: boolean
	isLoading: boolean
	isSubmitButtonLoading: boolean
}

const Section3 = ({
	isLoading,
	isAiLoading,
	authorIdeation,
	handleUserResponse,
	buttonDisabled,
	handleSubmitIdeas,
	isSubmitButtonLoading,
	cautionText,
}: Props) => {
	const richTextFieldRef = useRef(null)
	const richTextToIdeas = useRichTextToIdeas()

	return (
		<>
			<Typography
				marginY={'2rem'}
				color="black"
				sx={{ opacity: '87%', fontSize: '15px' }}
			>
				3. IDEATION
			</Typography>
			<Box
				display={'flex'}
				sx={{
					'.ProseMirror': {
						border: '1.5px solid var(--color-gray-4)',
					},
					'.ProseMirror-focused': {
						border: '2.5px solid var(--color-gray-4)',
					},
				}}
			>
				<Box marginRight={'2rem'} sx={Section1Styles.sideBar} />
				<Box
					display={'flex'}
					flexDirection={'column'}
					rowGap={'3rem'}
					flexGrow="1"
				>
					<Grid
						item
						xs={12}
						sm={10}
						sx={{
							minHeight: { xs: '15rem', sm: '20rem' },
						}}
					>
						<RichTextField
							ref={richTextFieldRef}
							onChange={handleUserResponse}
							value={authorIdeation}
							placeholderText="Brainstorm your ideas here!"
							iconColor="#45657B"
							sx={{
								background: '#E4F4D8',
								border: '1.5px solid rgba(0,0,0,0.23)',
							}}
						/>
					</Grid>
					<Box>
						<IdeaFlameMotivator
							ideaCount={richTextToIdeas(authorIdeation)}
							variant="LEAP_FROG"
						/>
					</Box>
					<Box>
						<Button
							color="secondary"
							variant="contained"
							data-testid={`${TEST_ID}__submit-idea-button`}
							onClick={handleSubmitIdeas}
							disabled={
								buttonDisabled() || isLoading || isAiLoading
							}
						>
							{isLoading || isSubmitButtonLoading ? (
								<CircularProgress
									color="secondary"
									sx={{
										padding: '1rem',
									}}
								/>
							) : (
								<Typography>SUBMIT IDEAS</Typography>
							)}
						</Button>
					</Box>
					<Box>
						<Typography
							variant="body2"
							color="black"
							sx={{ opacity: 0.6 }}
						>
							{cautionText}
						</Typography>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default Section3
