export const styles = {
	btnContainer: {
		a: {
			margin: '1.5rem auto 5rem',
		},
		width: '50%',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'row',
		margin: '0 auto',
		gap: '30px',
	},
	TextContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: '40px',
	},
	TextField: {
		color: 'secondary.main',
		fontWeight: 'bold',
		fontSize: '60px',
	},
	IconContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		pb: '2.5rem',
		pt: '5rem',
	},
	quoteText: {
		color: 'secondary.main',
		display: 'flex',
		fontSize: '25px',
		justifyContent: 'center',
		margin: '.5rem 0 0 0',
		textAlign: 'center',
		width: '100%',
	},
}
