import features, { FEATURE_FLAGS } from './featureFlags'

export function createFeatureDecisions() {
	return {
		enableRedesignedGamePages(): boolean {
			return features[FEATURE_FLAGS.SHOW_REDESIGNED_GAMEPAGES]
		},
		enableWaitingOnParticipantsBackend(): boolean {
			return features[FEATURE_FLAGS.WAITING_ON_PARTICIPANTS_BACKEND]
		},
	}
}
