import { OAuthPopup } from '@tasoskakour/react-use-oauth2'
import React from 'react'
import { Outlet, createBrowserRouter } from 'react-router-dom'

import { createFeatureDecisions } from 'config/featureDecisions'

import BattleShips from 'pages/BattleShips'
import DressUp from 'pages/DressUp'
import ExpiredPage from 'pages/ExpiredPage'
import HomePage from 'pages/HomePage'
import LeapFrog from 'pages/LeapFrog'
import LoggedOutPage from 'pages/LoggedOutPage'
import MusicalChairs from 'pages/MusicalChairs/v2'
import ProfilePage from 'pages/Profile/Profile'
import PublishedGame from 'pages/PublishedGame'
import PublishedGames from 'pages/PublishedGames'
import RoundRobinPage from 'pages/RoundRobin'
import SimonSays from 'pages/SimonSays/v2'
import SubmittedIdeas from 'pages/SubmittedIdeas/v2'
import TeeterTotter from 'pages/TeeterTotter/v2'

import ArcNavBarComponent from 'components/ArcNavBar'
import Footer from 'components/Footer'
import PageNotFound from 'components/PageNotFound'
import RequireAuth from 'components/RequireAuth/RequireAuth'

import {
	BATTLE_SHIPS,
	CUSTOM_GAMES,
	DRESS_UP,
	EXPIRED_PAGE,
	GAMES_PAGE,
	HOME_PAGE,
	LEAP_FROG,
	LOGGED_OUT_PAGE,
	MUSICAL_CHAIRS,
	PROFILE_PAGE,
	PUBLISHED_GAMES,
	PUBLISHED_GAME_ROUTE_TEMPLATE,
	ROUND_ROBIN,
	SIMON_SAYS,
	SUBMITTED_IDEAS,
	TEETER_TOTTER,
} from 'assets/routes'

function Layout() {
	return (
		<>
			<ArcNavBarComponent />
			<main>
				<Outlet />
			</main>
			<Footer />
		</>
	)
}

const router = createBrowserRouter([
	{
		element: <Layout />, // layout includes: NavBar and Footer
		children: [
			{ path: '/callback', element: <OAuthPopup /> },
			{
				path: HOME_PAGE,
				element: (
					<RequireAuth guestUserAllowed={false}>
						<HomePage />
					</RequireAuth>
				),
			},
			{
				path: PROFILE_PAGE,
				element: (
					<RequireAuth guestUserAllowed={false}>
						<ProfilePage />
					</RequireAuth>
				),
			},
			{
				path: GAMES_PAGE,
				children: [
					{
						path: BATTLE_SHIPS,
						element: (
							<RequireAuth guestUserAllowed={false}>
								<BattleShips />
							</RequireAuth>
						),
					},
					{
						path: SIMON_SAYS,
						element: (
							<RequireAuth guestUserAllowed={false}>
								<SimonSays />
							</RequireAuth>
						),
					},
					{
						path: LEAP_FROG,
						element: (
							<RequireAuth guestUserAllowed={false}>
								<LeapFrog />
							</RequireAuth>
						),
					},
					{
						path: DRESS_UP,
						element: (
							<RequireAuth guestUserAllowed={false}>
								<DressUp />
							</RequireAuth>
						),
					},
					{
						path: MUSICAL_CHAIRS,
						element: (
							<RequireAuth guestUserAllowed={false}>
								<MusicalChairs />
							</RequireAuth>
						),
					},
					{
						path: ROUND_ROBIN,
						element: (
							<RequireAuth guestUserAllowed={false}>
								<RoundRobinPage />
							</RequireAuth>
						),
					},
					{
						path: TEETER_TOTTER,
						element: (
							<RequireAuth guestUserAllowed={false}>
								<TeeterTotter />
							</RequireAuth>
						),
					},
				],
			},
			{
				path: PUBLISHED_GAME_ROUTE_TEMPLATE,
				element: (
					<RequireAuth guestUserAllowed={false}>
						<PublishedGame />
					</RequireAuth>
				),
			},
			{
				path: createFeatureDecisions().enableRedesignedGamePages()
					? CUSTOM_GAMES
					: PUBLISHED_GAMES,
				element: (
					<RequireAuth guestUserAllowed={false}>
						<PublishedGames />
					</RequireAuth>
				),
			},
			{
				path: PROFILE_PAGE,
				element: (
					<RequireAuth guestUserAllowed={false}>
						<ProfilePage />
					</RequireAuth>
				),
			},
			{
				path: SUBMITTED_IDEAS,
				element: (
					<RequireAuth guestUserAllowed={false}>
						<SubmittedIdeas />
					</RequireAuth>
				),
			},
			{
				path: EXPIRED_PAGE,
				element: (
					<RequireAuth>
						<ExpiredPage />
					</RequireAuth>
				),
			},
			{
				path: '*',
				element: (
					<RequireAuth guestUserAllowed={true}>
						<PageNotFound />
					</RequireAuth>
				),
			},
			{ path: LOGGED_OUT_PAGE, element: <LoggedOutPage /> },
		],
	},
])

export default router
