import { EditorContent, useEditor } from '@tiptap/react'
import React, { ForwardedRef, forwardRef, useImperativeHandle } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { SxProps, Theme } from '@mui/system'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import classNames from 'classnames'

import AudioTranscriptionButton from 'components/AudioTranscription'
import IconBullets from 'components/Icon/IconBullets'
import IconItalics from 'components/Icon/IconItalics'
import IconLink from 'components/Icon/IconLink'
import IconOrderList from 'components/Icon/IconOrderList'
import IconUnderline from 'components/Icon/IconUnderline'

import { LinkModal } from './LinkModal'
import { styles } from './RichTextField.styles'
import './styles.css'

export interface Props {
	fillheight?: boolean
	iconColor?: string
	onChange: (e: string) => void
	placeholderText?: string
	sx?: SxProps<Theme> | undefined
	value: string
}

export type TiptapMethods = {
	pasteContent: (value: string) => void
}

const RichTextField = forwardRef(
	(
		{
			onChange,
			value,
			placeholderText,
			iconColor,
			fillheight = true,
			sx = {},
		}: Props,
		ref: ForwardedRef<TiptapMethods>
	) => {
		const [modalIsOpen, setIsOpen] = React.useState(false)
		const [url, setUrl] = React.useState<string>('')
		const [label, setLabel] = React.useState<string>('')

		// define extension array
		const extensions = [
			StarterKit.configure({
				bold: false,
			}),
			Underline,
			Link.configure({
				validate: (href) => /^https?:\/\//.test(href),
			}),
			Placeholder.configure({
				placeholder: placeholderText ?? 'Enter your ideas here...',
			}),
		]

		const editor = useEditor({
			extensions,
			content: value,
			onUpdate({ editor }) {
				onChange(editor.getHTML())
			},
		})

		useImperativeHandle(ref, () => ({
			pasteContent: (value) => {
				if (editor) {
					editor.commands.focus('end')
					if (!editor.isEmpty) {
						editor.commands.insertContent('<p></p>')
					}
					editor.commands.insertContent(value)
				}
			},
		}))

		React.useEffect(() => {
			if (editor && editor.commands && value === '') {
				editor.commands.setContent('')
			}
		}, [editor, value])

		React.useEffect(() => {
			if (editor && editor.view) {
				const { state } = editor.view
				const { from, to } = state.selection
				const text = state.doc.textBetween(from, to, ' ')
				if (text) setLabel(text)
			}
		}, [editor, editor?.view.state.selection])

		const openModal = React.useCallback(() => {
			setUrl(editor?.getAttributes('link').href)
			setIsOpen(true)
		}, [editor])

		const closeModal = React.useCallback(() => {
			setIsOpen(false)
			setUrl('')
			setLabel('')
		}, [])

		const removeLink = React.useCallback(() => {
			editor?.chain().focus().extendMarkRange('link').unsetLink().run()
			closeModal()
		}, [editor, closeModal])

		const saveLink = () => {
			editor
				?.chain()
				.focus()
				.extendMarkRange('link')
				.setLink({ href: url, target: '_blank' })
				.run()

			updateSelectedText()

			closeModal()
		}

		const updateSelectedText = () => {
			const selection = editor?.view.state.selection
			editor
				?.chain()
				.focus()
				.insertContentAt(
					{
						from: selection?.from ?? 0,
						to: selection?.to ?? 0,
					},
					label,
					{
						updateSelection: true,
					}
				)
				.run()
		}

		const toggleUnderline = React.useCallback(() => {
			editor?.chain().focus().toggleUnderline().run()
		}, [editor])

		const toggleItalic = React.useCallback(() => {
			editor?.chain().focus().toggleItalic().run()
		}, [editor])

		const toggleBulletList = React.useCallback(() => {
			editor?.chain().focus().toggleBulletList().run()
		}, [editor])

		const toggleOrderedList = React.useCallback(() => {
			editor?.chain().focus().toggleOrderedList().run()
		}, [editor])

		const gridSx = editor?.isFocused
			? {
					...styles.menuFocused,
					...sx,
					borderWidth: '2.5px',
				}
			: {
					...styles.menu,
					backgroundColor: '#E0E0E0',
					borderColor: '#949fab',
					...sx,
				}
		return (
			<Box
				sx={{
					...(fillheight ? styles.editorBoxFull : styles.editorBox),
				}}
				className="editor"
			>
				<Grid
					container
					sx={gridSx as SxProps | undefined}
					className="menu"
				>
					<Grid
						item
						display="flex"
						sx={{
							marginLeft: '0.5rem',
						}}
					>
						<button
							className={classNames('menu-button', {
								'is-active': editor?.isActive('italic'),
							})}
							onClick={toggleItalic}
							aria-label="italics-icon"
						>
							<IconItalics color={iconColor || '#cf4f27'} />
						</button>
						<button
							className={classNames('menu-button', {
								'is-active': editor?.isActive('underline'),
							})}
							onClick={toggleUnderline}
							aria-label="underline-icon"
						>
							<IconUnderline color={iconColor || '#cf4f27'} />
						</button>
						<button
							className={classNames('menu-button', {
								'is-active': editor?.isActive('bulletList'),
							})}
							onClick={toggleBulletList}
							aria-label="bullets-icon"
						>
							<IconBullets color={iconColor || '#cf4f27'} />
						</button>
						<button
							className={classNames('menu-button', {
								'is-active': editor?.isActive('orderedList'),
							})}
							onClick={toggleOrderedList}
							aria-label="order-list-icon"
						>
							<IconOrderList color={iconColor || '#cf4f27'} />
						</button>
						<button
							className={classNames('menu-button', {
								'is-active': editor?.isActive('link'),
							})}
							onClick={openModal}
							aria-label="link-icon"
						>
							<IconLink color={iconColor || '#cf4f27'} />
						</button>
						<AudioTranscriptionButton editor={editor} />
					</Grid>
					<Grid item mr={1}>
						{/* TODO: uncomment when implementing favorite feature */}
						{/* <button
							className={classNames('menu-button', {
								'is-active': true,
							})}
						>
							<img
								src={HeartIcon as unknown as string}
								alt="heart icon"
							/>
						</button> */}
					</Grid>
				</Grid>
				<EditorContent
					editor={editor}
					style={{ wordBreak: 'break-all' }}
				/>
				<LinkModal
					url={url}
					open={modalIsOpen}
					closeModal={closeModal}
					onChangeUrl={(e) => setUrl(e.target.value)}
					onSaveLink={saveLink}
					onRemoveLink={removeLink}
					label={label}
					onChangeLabel={(e) => setLabel(e.target.value)}
				/>
			</Box>
		)
	}
)

RichTextField.displayName = 'RichTextField'

export default RichTextField
