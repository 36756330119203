import React from 'react'

import { Box, Typography } from '@mui/material'

import RectangleButtonComponent from 'components/RectangleButton'

import { cta } from 'assets/callToActionParams'
import NotFoundIcon from 'assets/images/404.svg'
import { HOME_PAGE } from 'assets/routes'

import { styles } from './PageNotFound.styles'

const PageNotFound = () => {
	return (
		<Box data-testid="page__not__found">
			<Box></Box>
			<Box sx={styles.IconContainer}>
				<img
					alt="not-found-icon"
					src={NotFoundIcon as unknown as string}
				></img>
			</Box>
			<Box sx={styles.TextContainer}>
				<Typography sx={styles.TextField}>
					Oops! No bright ideas found here
				</Typography>
			</Box>
			<Box sx={styles.btnContainer}>
				<RectangleButtonComponent
					data-testid="return__home__button"
					href={HOME_PAGE}
					text={'return home'}
				/>
				<RectangleButtonComponent
					data-testid="page__not__found__request-button"
					href={`mailto:arclabs@slalom.com?${Object.entries(cta)
						.map(([k, v]) => `${k}=${v}`)
						.join('&')}`}
					target="_blank"
					text={'request help'}
				/>
			</Box>
			<Box sx={styles.TextContainer}>
				<Typography sx={styles.quoteText}>
					<i>&quot;Progress is not linear.&quot;&nbsp;</i> - Maria
					Montessori
				</Typography>
			</Box>
		</Box>
	)
}

export default PageNotFound
