/*
	BRAINSTORM TOAST USAGE:

    const { showAlert } = useToastContext()

	// SUCCESS Toast with default text
		
		showAlert()

	// SUCCESS Toast with custom title and text

		showAlert({ title: 'Super Special Title', bodyText: 'Text you probably skimmed' })

	// ERROR Toast with default text

		showAlert({ severity: ToastSeverity.ERROR })

	// ERROR Toast with custom title and text

		showAlert({ title: 'Angry Red Heading', bodyText: 'Equally angry body', severity: ToastSeverity.ERROR })
*/
import React, {
	FC,
	ReactNode,
	createContext,
	useContext,
	useState,
} from 'react'

import ToastAlertsComponent from 'components/ToastAlerts'

import { ToastSeverity } from 'enums/ToastSeverityEnum'

export type ToastContents = {
	bodyText?: string
	gameName?: string
	objectType?:
		| 'submitIdea'
		| 'createGame'
		| 'deleteGame'
		| 'updateGame'
		| 'copyGameLink'
		| 'sendGameInvite'
		| 'resetPassword'
		| 'editPrompt'
		| 'deleteIdea'
		| 'defaultError'
	severity: ToastSeverity
	title?: string
}

type ToastContextType = {
	children?: ReactNode
	showAlert?: ({
		title,
		bodyText,
		severity,
		objectType,
	}: ToastContents) => void
}

const initialToastState: ToastContents = {
	bodyText: '',
	objectType: 'submitIdea',
	severity: ToastSeverity.SUCCESS,
	title: '',
}

export const ToastContext = createContext<ToastContextType>({
	showAlert: () => ({
		...initialToastState,
	}),
})

export const useToastContext = () => useContext(ToastContext)

export const ToastProvider: FC<ToastContextType> = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [alert, setAlert] = useState<ToastContents>(initialToastState)

	const handleClose = () => {
		setIsOpen(false)
	}

	const showAlert = (contents: ToastContents) => {
		contents &&
			setAlert({
				...initialToastState, // resets to defaults for ease-of-use
				...contents,
			})
		setIsOpen(true)
	}

	return (
		<ToastContext.Provider
			value={{
				showAlert,
			}}
		>
			<ToastAlertsComponent
				handleCloseAlert={handleClose}
				open={isOpen}
				{...alert}
			/>
			{children}
		</ToastContext.Provider>
	)
}
