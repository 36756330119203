import { ToastSeverity } from 'enums/ToastSeverityEnum'

const alertTitleTextMap = {
	submitIdea: {
		title: 'Your ideas have been successfully submitted!',
		text: '',
	},
	submitIdeaError: {
		title: 'Uh oh, error submitting your ideas',
		text: "This isn't your fault, try again later",
	},
	deleteIdea: {
		title: 'Idea has been deleted!',
		text: '',
	},
	editIdea: {
		title: 'Idea has been updated!',
		text: '',
	},
	genAiError: {
		title: 'Whoops, error generating a scenario',
		text: 'GenAI can be finicky, please try again',
	},
	createGame: {
		title: 'Game created successfully. ↗',
		text: 'Copy the game link to share!',
	},
	deleteGame: {
		title: 'game deleted.',
		text: '',
	},
	updateGame: {
		title: 'game updated.',
		text: '',
	},
	copyGameLink: {
		title: 'Game link copied.',
		text: '',
	},
	sendGameInvite: {
		title: 'Game invite sent successfully.',
		text: 'check submitted ideas for responses',
	},
	resetPassword: {
		title: 'Password was reset successfully',
		text: '',
	},
	editPrompt: {
		title: 'GenAI prompt updated!',
		text: '',
	},
	defaultError: {
		title: `This feature isn't working right now`,
		text: 'Please try again later.',
	},
}

const defaultErrorMessage = {
	bodyText: alertTitleTextMap.defaultError.text,
	severity: ToastSeverity.ERROR,
	title: alertTitleTextMap.defaultError.title,
}

export { alertTitleTextMap, defaultErrorMessage }
